import { withMembersArea, MA_APP_IDS } from '@wix/members-area-integration-kit';

const fileShareAppDefId = '1537b24e-29d1-6d8f-b8e1-d6860f2f70b9';

let appToken;
let sdk;

const getAppManifest = ({ appManifestBuilder }, _editorSDK) => {
  return appManifestBuilder.configureManagementActions(managementActionsBuilder => {
    managementActionsBuilder.mainActions()
      .addAction({
        title: 'Create Post',
        icon: 'createAPost',
        onClick: (event) => {},
      }, {
        title: 'Add Product Page',
        icon: 'addAProduct',
        onClick: (event) => {},
      });

    managementActionsBuilder.customActions()
      .addAction({
        title: 'Open Stores Pages',
        icon: 'openPagesPanelUrl',
        type: 'editorActions',
        onClick: (event) => {
          _editorSDK.editor.openDashboardPanel('', {
            url: '',
            closeOtherPanels: false,
          });
        },
      }, {
        title: 'Add Stores Galleries',
        icon: 'openAddPanelOnStoresGalleriesUrl',
        type: 'editorActions',
        onClick: (event) => {
          _editorSDK.editor.openComponentPanel('', {
            url: 'http://localhost:9001/settings',
            title: 'My Component Settings',
            type: _editorSDK.editor.PanelType.Settings
          });
        },
      }, {
        title: 'Add Related Widgets',
        icon: 'openAddPanelOnStoresWidgetsUrl',
        type: 'editorActions',
        onClick: (event) => {},
      }, {
        title: 'Manage Collections',
        icon: 'manageCollectionsUrl',
        type: 'dashboard',
        onClick: (event) => {},
      }, {
        title: 'Manage Products',
        icon: 'manageProductsUrl',
        type: 'dashboard',
        onClick: (event) => {},
      });
  }).build();
}

const getFileShareEditorApi = () => ({
  editorReady: async function (_editorSDK, _appToken) {
    appToken = _appToken;
    sdk = _editorSDK;
  },
  getAppManifest,
  handleAction: function (args) {
    const type = args.type
    const payload = args.payload;
    try {
      switch (type) {
        case 'appInstalled':
          switch (payload.appDefinitionId) {
            case fileShareAppDefId: {
              return sdk.pages.data.getAll(appToken)
                .then(allPages => {
                  var filesPage = allPages.find(page => page.tpaPageId === 'files' && page.tpaApplicationId === fileShareAppDefId);
                  return filesPage && sdk.document.pages.navigateTo(appToken, { pageLink: { type: 'PageLink', pageId: filesPage.id } });
                })
                .then(() => sdk.document.save());
            }
            default:
              return Promise.resolve()
          }
        default:
          // console.log(type, payload);
          return Promise.resolve();
      }
    }
    catch (e) {
      Promise.reject();
      throw e;
    }
  }
})

const editorApp = withMembersArea(getFileShareEditorApi(), {
  membersAreaApps: [
    MA_APP_IDS.ALL_MEMBERS,
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.FILE_SHARE,
  ]
});

export const editorReady = editorApp.editorReady;
export const handleAction = editorApp.handleAction;
